<template>
  <div class="ys-cards">
      <b-card no-body
              :class="{
              activesprint: sprint.status_id == activeSprintStatus,
              startedSprint: startedSprint && sprint.uuid == startedSprint.uuid }">
        <b-card-header >
          <div class="d-flex justify-content-between">
            <div class="d-flex" v-b-toggle="'collapse-'+sprint.uuid">
              <feather-icon
                icon="ChevronRightIcon"
                size="14"
              />
              <h6>
                {{ sprint.name }}
                <small class="text-muted">{{ sprint.tasks.length }} задач(и)</small>
                <small class="ml-2" v-if="sprint.started_at.length">
                  {{ sprint.started_at | formatDateOnly }} - {{ sprint.expired_at | formatDateOnly }}
                </small>
              </h6>
            </div>
          </div>
          
          <div class="d-flex align-items-center">
            <!--<b-badge class="mr-1">3нед, 4дн, 6ч</b-badge>
            <b-badge variant="primary" class="mr-1">3нед, 6ч</b-badge>
            <b-badge variant="success" class="mr-1">0мин</b-badge>-->
  
            <b-dropdown
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              size="sm"
              no-caret
              right
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="14"
                  class="text-body cursor-pointer"
                />
              </template>
  
              <b-dropdown-item @click="startSprint"  v-if="!issueActiveSprint">
                Начать спринт
              </b-dropdown-item>
  
              <b-dropdown-item @click="stopSprint"  v-if="activeSprintStatus == sprint.status_id">
                Завершить спринт
              </b-dropdown-item>
              
                <b-dropdown-item @click="editSprint">
                  Редактировать спринт
                </b-dropdown-item>
  
              <b-dropdown-item @click="deleteSprint" v-if="activeSprintStatus != sprint.status_id">
                Удалить спринт
              </b-dropdown-item>

            </b-dropdown>
          </div>
          <div v-if="sprint.started_at.length" class="slk-active-sprint-desc" v-html="sprint.description"></div>
        </b-card-header>
        <b-collapse :id="'collapse-'+sprint.uuid" :visible="false">
          <div>
            <!-- Todo List -->
            <vue-perfect-scrollbar
              :settings="perfectScrollbarSettings"
              class="ys-tasks__block"
  
            >
              <draggable
                handle=".draggable-task-handle"
                tag="ul"
                class="ys-tasks__list"
                :list="sprint.tasks"
                :data-sprint="sprint.uuid"
                :data-isactive="isActiveSprint"
                :group="{name:'sprint' }"
                @end="onEnd"
              >
                <task
                  v-if="sprint.tasks.length > 0"
                  v-for="task in sprint.tasks"
                  :key="task.id"
                  :task="task"
                  @click.native="$emit('openTask', task)"
                ></task>
              </draggable>
            </vue-perfect-scrollbar>
            <!--<div class="ys-tasks__list">
              <a href="#"><small>Создать задачу</small></a>
            </div>-->
            
          </div>

        <!--<div v-else>
          <div class="ys-tasks__list">
            <p class="mt-1">Нет задач</p>
          </div>
    
        </div>-->
        </b-collapse>
      </b-card>
  
  </div>
</template>

<script>
import {
    BCard, BCardHeader, BButton, BDropdown, BDropdownItem, BDropdownGroup, BSidebar, VBToggle, BCollapse, BBadge
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import draggable from 'vuedraggable'
import Task from "../task/Task"
import TaskContent from "../task/TaskContent";
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { updateTask, backlogDnDEnd } from "@/mixins/task";
import { mapGetters, mapActions } from "vuex";
import { projectApi } from "@/api";

export default {
  name: "BacklogSprint",
  props: {
    sprint: [Object],
    activeSprintStatus: [Number]
  },
  components: {
    BCard,
    BCardHeader,
    BButton,
    BDropdown,
    BDropdownItem,
    BDropdownGroup,
    draggable,
    VuePerfectScrollbar,
    Task,
    BSidebar,
    TaskContent,
    AppCollapse,
    AppCollapseItem,
    BCollapse,
		  BBadge
  },
  mixins: [updateTask, backlogDnDEnd],
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data: function() {
      return {
		      activeTask: null,
		      perfectScrollbarSettings: {
				      maxScrollbarLength: 150,
		      },
      }
  },
  computed: {
		  ...mapGetters("project", ["issueActiveSprint", "startedSprint"]),
  		isActiveSprint() {
  				return this.sprint.status_id == this.activeSprintStatus ? 1 : 0
      },
  },
		methods: {
  		  ...mapActions("project", ["getSprints"]),
        editSprint() {
          this.$store.dispatch("project/updateOpenSprint", this.sprint)
          this.$bvModal.show('modal-create-sprint');
        },
        stopSprint() {
		        this.$store.dispatch("project/updateOpenSprint", this.sprint)
		        this.$bvModal.show('modal-stop-sprint');
        },
        startSprint() {
		        this.$store.dispatch("project/updateOpenSprint", this.sprint)
		        this.$bvModal.show('modal-start-sprint');
        },
        deleteSprint() {
		        let result = confirm(this.$t("Are you sure you want to delete the sprint?"));
		        if(result) {
                projectApi.deleteSprint(this.sprint.uuid).then((res) => {
                    this.getSprints();
                }).catch((error) => {
		                this.$bvToast.toast(`Ошибка удаления спринта.`, {
				                title: 'Удаление спринта',
				                variant: "danger",
				                solid: true,
		                });
                });
            }
		       
        },
        onEnd(event) {
            this.showMessage(event);
            const { task_number, to } = this.getDnDRes(event);
            this.updateTask(task_number, {
                sprint_uuid: to
            });
        },
		},
}
</script>

<style scoped>
.activesprint, .activesprint.startedSprint {
  border: 1px solid #7367f0;
  border-radius: 4px;
}
.startedSprint {
  border: 1px solid #82868b;
  border-radius: 4px;
}
  .slk-active-sprint-desc {
    width: 100%;
    padding-left: 15px;
  }
</style>
