<template>
  <div>
    <div class="slk-loader" v-if="isPending">
      <b-spinner label="Loading..." />
    </div>
    <backlog-nav
      @search="search"
      @filterAssignee="filterAssignee"
      :config="projectConfig"
      v-if="!activeEpic"
    ></backlog-nav>
    
   
    <div  class="ys-backlog d-flex mt-2">
      <div class="ys-backlog-tab-block" v-if="!isShowTab">
        <div class="ys-backlog-tab">
          <div class="ys-backlog-tab-badge" v-b-tooltip.hover.right title="Панель верстий" @click="showTabs(0)">Версии</div>
          <div class="ys-backlog-tab-badge" v-b-tooltip.hover.right title="Панель эпиков" @click="showTabs(1)">Эпики</div>
        </div>
      </div>
     
      <backlog-tab
        v-if="isShowTab"
        @close="closeTabs"
        :tabIndex="tabIndex"
      ></backlog-tab>
      <epic-tasks v-if="activeEpic"></epic-tasks>
      <div v-else class="w-100">
        <backlog-task
          v-if="filterTasks"
          :tasks="filterTasks"
          :sprints="filterSprints"
          :activeSprintStatus="activeSprintStatus"
        ></backlog-task>
      </div>
      
    </div>
    
    
    <modal-sprint-form :sprint="editSprint"></modal-sprint-form>
    
    <backlog-stop-sprint></backlog-stop-sprint>
    
    <backlog-start-sprint></backlog-start-sprint>
  
    <b-modal
      id="warning-drag-modal"
      title="Перенос задачи"
      ok-only
      ok-title="Ok"
    >
      <b-alert
        show
        variant="success"
      >
        <div class="alert-body" id="warningDragModalText"></div>
      </b-alert>
    </b-modal>
    
  </div>

</template>

<script>
import { BSpinner, BModal, BAlert } from 'bootstrap-vue'
import BacklogNav from "./BacklogNav";
import BacklogTab from "./BacklogTab";
import BacklogTask from "./BacklogTask";
import { VBTooltip } from 'bootstrap-vue';
import ModalSprintForm from "./ModalSprintForm";
import BacklogStartSprint from "./BacklogStartSprint";
import BacklogStopSprint from "./BacklogStopSprint";
import { mapActions, mapState, mapGetters } from "vuex";
import { projectApi } from "@/api";
import EpicTasks from "./BacklogEpicTasks";
import axios from 'axios'

export default {
  name: "Backlog",
  components: {
		  BacklogNav,
		  BacklogTab,
		  BacklogTask,
		  ModalSprintForm,
		  BSpinner,
		  BModal,
		  BAlert,
		  BacklogStartSprint,
		  BacklogStopSprint,
		  EpicTasks
  },
  directives: {
      'b-tooltip': VBTooltip,
  },
  computed: {
      ...mapState("project", ["backlogTasks", "currentProject", "sprints", "activeSprintStatus", "activeEpic", "isPending"]),
		  ...mapGetters("project", ["sortSprints"]),
  },
  data: function() {
    return {
      isShowTab: false,
      filterTasks: null,
      filterSprints: null,
      projectConfig: null,
      editSprint: null,
      tabIndex: 1,
    }
  },
  methods: {
		  ...mapActions("project", ["getBacklogTasks", "getSprints", "getActiveSprintStatus", "updatePending"]),
		  showTabs(number) {
		  		this.tabIndex = number;
          this.isShowTab = true;
      },
      closeTabs() {
		      this.isShowTab = false;
		      this.$store.dispatch('project/updateActiveEpic', null);
      },
		  search(search) {
		  		if(search.length > 0) {
              this.filterTasks = this.backlogTasks.filter((item) => {
              		return item.key.toLowerCase().includes(search.toLowerCase()) || item.name.toLowerCase().includes(search.toLowerCase())
              });
              
              // Глубокое копирование массива
              const searchSprint = JSON.parse(JSON.stringify(this.sprints));
	
              this.filterSprints = searchSprint.map((item) => {
                item.tasks = item.tasks.filter((item) => {
                  return item.key.toLowerCase().includes(search.toLowerCase()) || item.name.toLowerCase().includes(search.toLowerCase())
                });
                return item;
              });
          } else {
              this.filterTasks = this.backlogTasks;
              this.filterSprints = this.sprints;
          }
      },
		  filterAssignee(assignee) {
		  		if(assignee.length > 0) {
              this.filterTasks = this.backlogTasks.filter((item) => {
		              return item.assignee ? assignee.includes(item.assignee.id) : assignee.includes(item.assignee);
              });
										
              // Глубокое копирование массива
              const searchSprint = JSON.parse(JSON.stringify(this.sprints));
            
              this.filterSprints = searchSprint.map((item) => {
		              item.tasks = item.tasks.filter((item) => {
                      return item.assignee ? assignee.includes(item.assignee.id) : assignee.includes(item.assignee);
		              });
		              return item;
              });
          } else {
              this.filterTasks = this.backlogTasks;
              this.filterSprints = this.sprints;
          }
      },
		  loadData() {
        this.$store.dispatch('project/updatePending', true);
				return axios.all([this.getBacklogTasks(), this.getSprints()])
          .then(() => {
              this.filterTasks = this.backlogTasks;
              this.filterSprints = this.sprints;
          })
          .catch(error => {
		          this.$bvToast.toast(`Ошибка при загрузке данных.`, {
				          title: this.$t("Data Update"),
				          variant: "danger",
				          solid: true,
		          });
          })
          .then(()=> this.$store.dispatch('project/updatePending', false));
		  }
  },
  watch: {
		  backlogTasks: function (val) {
          this.filterTasks = val;
      },
		  sprints: function (val) {
          this.filterSprints = this.sortSprints;
		  },
		  currentProject: function () {
          this.loadData();
      },
  },
  mounted() {
		  this.getActiveSprintStatus().then(() => {
            this.loadData();
      });
		  projectApi.getConfig(this.currentProject).then(res => {
            this.projectConfig = res.data.project_config;
      });
  },
}
</script>

<style scoped>

</style>
