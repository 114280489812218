<template>
  <div class="ys-cards__wrap">
    <backlog-sprint
      v-if="sprints"
      v-for="sprint in sprints"
      :key="sprint.id"
      :sprint="sprint"
      :activeSprintStatus="activeSprintStatus"
      @openTask="openTask"
    ></backlog-sprint>
    <div class="ys-cards">
      <b-card no-body>
        <b-card-header>
          <h6>Бэклог <small class="text-muted">{{ tasks.length }} задач(и)</small></h6>
        
          <div>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              size="sm"
              class="mr-1"
              @click="$bvModal.show('modal-create-sprint')"
            >
              Создать спринт
            </b-button>
          
            <b-dropdown
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              size="sm"
              no-caret
              right
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="14"
                  class="text-body cursor-pointer"
                />
              </template>
              <b-dropdown-group
                id="dropdown-group-1"
                header="Всегда показвать"
              >
                <b-dropdown-item>
                  100 issue
                </b-dropdown-item>
                <b-dropdown-item>
                  500 issue
                </b-dropdown-item>
                <b-dropdown-item>
                  All
                </b-dropdown-item>
              </b-dropdown-group>
          
            </b-dropdown>
          </div>
        </b-card-header>
        
        <!-- Todo List -->
        <vue-perfect-scrollbar
          :settings="perfectScrollbarSettings"
          class="ys-tasks__block"
        >
          <draggable
            handle=".draggable-task-handle"
            tag="ul"
            class="ys-tasks__list"
            @end="onEnd"
            data-sprint="0"
            :list="tasks"
            :group="{name:'sprint' }"
          >
            <task
              v-for="task in tasks"
              :key="task.id"
              :task="task"
              @click.native="openTask(task)"
            ></task>
          </draggable>
        </vue-perfect-scrollbar>
      </b-card>
    
      <b-sidebar
        id="sidebar-right"
        bg-variant="white"
        right
        backdrop
        shadow
        class="sidebar-task"
        no-header
        @hidden="hidden"
      >
        <template #default="{ hide }">
          <div class="ys-task__close" @click="hide">
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="18"
            />
          </div>
          <task-aside
            v-if="activeTask"
            :task="activeTask"
          ></task-aside>
        </template>
    
      </b-sidebar>
  
    </div>
    
  </div>

</template>

<script>
import {
    BCard, BCardHeader, BButton, BDropdown, BDropdownItem, BDropdownGroup, BSidebar, VBToggle
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import draggable from 'vuedraggable'
import Task from "../task/Task"
import TaskContent from "../task/TaskContent";
import BacklogSprint from "./BacklogSprint";
import TaskAside from "../task/TaskAside";
import { mapState } from "vuex";
import { taskApi } from "@/api";
import { updateTask, backlogDnDEnd } from "@/mixins/task";

export default {
    name: "BacklogTask",
		components: {
				BCard,
				BCardHeader,
        BButton,
        BDropdown,
        BDropdownItem,
        BDropdownGroup,
        draggable,
        VuePerfectScrollbar,
        Task,
        BSidebar,
        TaskContent,
        BacklogSprint,
        TaskAside
		},
		directives: {
				Ripple,
        'b-toggle': VBToggle,
		},
    props: {
        tasks: [Array],
		    sprints: [Array],
		    activeSprintStatus: [Number]
    },
		mixins: [updateTask, backlogDnDEnd],
    data: function() {
      return {
        perfectScrollbarSettings: {
            maxScrollbarLength: 150,
        },
      }
    },
  computed: {
		  ...mapState("project", ["currentProject", "activeTask"]),
  },
  methods: {
    onEnd(event) {
		    this.showMessage(event);
		    const { task_number, to } = this.getDnDRes(event);
		    this.updateTask(task_number, {
				    sprint_uuid: to
		    });
    },
    hidden() {
      this.$store.dispatch('project/updateActiveTask', null);
    },
    openTask(task) {
      taskApi.task(this.currentProject, task.number).then((res) => {
      		this.$store.dispatch('project/updateActiveTask', res.data.task);
		      this.$root.$emit('bv::toggle::collapse', 'sidebar-right');
      });
    },
  },
}
</script>

<style scoped>

</style>
