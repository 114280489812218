<template>
  <div class="position-relative w-100">
    <div class="slk-loader w-100" v-if="isloader">
      <b-spinner label="Loading..." />
    </div>
    <div class="ys-cards">
      <b-card no-body>
        <b-card-header>
          <h6>{{ activeEpic.caption }} <small class="text-muted">{{ filterTasks.length }} задач(и)</small></h6>
        </b-card-header>
      
        <div class="ys-tasks__list" v-if="filterTasks.length > 0">
          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="ys-tasks__block"
          >
            <task
              v-for="task in filterTasks"
              :key="task.id"
              :task="task"
              @click.native="openTask(task)"
            ></task>
          </vue-perfect-scrollbar>
      
        </div>
    
      </b-card>
    </div>
    <b-sidebar
      id="sidebar-right"
      bg-variant="white"
      right
      backdrop
      shadow
      class="sidebar-task sidebar-task-epic"
      no-header
      @hidden="hidden"
    >
      <template #default="{ hide }">
        <div class="ys-task__close" @click="hide">
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="18"
          />
        </div>
        <task-aside
          v-if="activeTask"
          :task="activeTask"
        ></task-aside>
      </template>
  
    </b-sidebar>
  </div>

</template>

<script>
import { BCard, BCardHeader, BButton, BSidebar, BSpinner } from 'bootstrap-vue'
import { mapState } from "vuex";
import Task from "../task/Task";
import TaskAside from "../task/TaskAside";
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { taskApi } from "@/api";

export default {
    name: "EpicTasks",
		components: {
				BCard,
				BCardHeader,
				BButton,
				Task,
				BSidebar,
				TaskAside,
        VuePerfectScrollbar,
        BSpinner
		},
		computed: {
				...mapState("project", ["activeEpic", "activeTask", "currentProject"]),
		},
		data: function() {
				return {
						filterTasks: [],
            perfectScrollbarSettings: {
                maxScrollbarLength: 150,
            },
            isloader: false,
				}
		},
    methods: {
        openTask(task) {
            taskApi.task(this.currentProject, task.number).then((res) => {
                this.$store.dispatch('project/updateActiveTask', res.data.task);
                this.$root.$emit('bv::toggle::collapse', 'sidebar-right');
            });
        },
		    hidden() {
				    this.$store.dispatch('project/updateActiveTask', null);
		    },
        loadTask() {
        		this.isloader = true;
		        this.filterTasks = [];
		        taskApi.getChildrenTask(this.currentProject, this.activeEpic.number).then((res) => {
                this.filterTasks = res.data.child_tasks;
		        }).catch(() => {
                this.$bvToast.toast(`Ошибка при загрузке данных.`, {
                    title: this.$t("Data Update"),
                    variant: "danger",
                    solid: true,
                });
            }).then(() => {
				        this.isloader = false;
          });
        },
    },
    watch: {
		    activeEpic: function(val) {
            this.loadTask();
        }
    },
    mounted() {
		    this.loadTask();
    }
}
</script>

<style scoped>

</style>
