<template>
  <div class="ys-backlog__aside">
    <div class="slk-loader block-loader" v-if="isPending">
      <b-spinner label="Loading..." />
    </div>
    <div class="d-flex justify-content-end">
      <feather-icon icon="XIcon" @click="$emit('close')" class="cursor-pointer"/>
    </div>
    
    <b-tabs v-model.sync="dataTabIndex">
      <b-tab title="Версии">
        <a href="javascript::void()" class="mb-50 d-block"><small>Создать версию</small></a>
        <b-list-group>
          <b-list-group-item href="#">Все задачи</b-list-group-item>
        </b-list-group>
        <app-collapse>
    
          <app-collapse-item isVisible title="1.105" class="ys-backlog__aside-collapse">
            <label for="example-datepicker" class="mt-50">Название</label>
            <b-form-input
              id="name"
              placeholder="Название"
              size="sm"
              value="Входит фича 'Где сорт'"
            />
            <a href="javascript::void()" class="d-block"><small>Подробности</small></a>
            <hr>
            <b-form-group
              label="Дата начала"
              label-for="name"
            >
              <b-form-datepicker
                id="example-datepicker"
                v-model="date2"
                class="mb-50"
                size="sm"
              />
            </b-form-group>
            <b-form-group
              label="Дата выпуска"
              label-for="name"
            >
              <b-form-datepicker
                id="example-datepicker"
                v-model="date1"
                class="mb-50"
                size="sm"
              />
            </b-form-group>
            <div class="d-flex mb-50">
              <small style="width:100px">Задачи</small>
              <b-badge>0</b-badge>
            </div>
            <div class="d-flex mb-50">
              <small style="width:100px">Завершено</small>
              <b-badge>0</b-badge>
            </div>
            <div class="d-flex mb-50">
              <small style="width:100px">Не оценено</small>
              <b-badge>0</b-badge>
            </div>
            <div class="d-flex mb-50">
              <small style="width:100px">Оценка</small>
              <b-badge>0</b-badge>
            </div>
          </app-collapse-item>
        </app-collapse>
        <b-list-group>
          <b-list-group-item href="#">Задачи без версий</b-list-group-item>
        </b-list-group>
      </b-tab>
      
      <b-tab title="Эпики">
        <a href="#" class="mb-50 d-block" @click.prevent="createEpic"><small>Создать эпик</small></a>
        <b-list-group>
          <b-list-group-item href="#" @click.prevent="showAllTask">Все задачи</b-list-group-item>
        </b-list-group>
        <div v-if="epics" class="collapse-default">
          <template v-for="epic in epics">
            <div  class="card ys-backlog__aside-collapse" >
              <div class="card-header">
                <span role="tab"  :aria-controls="'epic'+epic.number" @click="showEpicTask(epic)">{{ epic.caption }}</span>
                <div class="cursor-pointer  ys-backlog-collapse__icon" v-b-toggle="'epic'+epic.number" >
                  <feather-icon icon="ChevronDownIcon"/>
                </div>
                
              </div>
            </div>
            <b-collapse :id="'epic'+epic.number" class="ys-backlog__aside-collapse">
              <div class="card-body">
  
                <small class="mt-1 d-block" v-if="epic.key">
                  <a href="#" @click.prevent="openTask(epic)">{{ epic.key }} {{ epic.caption }}</a>
                  
                </small>
  
                <div class="d-flex mb-50 mt-1">
                  <small style="width:100px">Задачи</small>
                  <b-badge>{{ epic.count.total }}</b-badge>
                </div>
                <div class="d-flex mb-50">
                  <small style="width:100px">Завершено</small>
                  <b-badge>{{ epic.count.accepted }}</b-badge>
                </div>
                <div class="d-flex mb-50">
                  <small style="width:100px">Не оценено</small>
                  <b-badge>{{ epic.count.unestimated }}</b-badge>
                </div>
                <div class="d-flex mb-50">
                  <small style="width:100px">Оценка</small>
                  <b-badge>{{ epic.count.estimated }}</b-badge>
                </div>
  
                <short-task-form
                  :epicNumber="epic.number"
                  @callback="update"
                  v-if="openCreateTaskForm.includes(epic.number)"
                ></short-task-form>
  
                <a href="#"
                   class="mb-50 d-block mt-50"
                   @click="toggleCreateTaskForm(epic.number)"
                   v-if="epic.key"
                >
                  <small>{{ openCreateTaskForm.includes(epic.number) ? closeText  : createText }}</small>
                </a>
              </div>
       
            </b-collapse>
          </template>

        </div>
      </b-tab>
    </b-tabs>
  </div>
  
</template>

<script>
import { BTabs,
  BTab,
  BListGroup,
  BListGroupItem,
  BFormGroup,
  BFormInput,
  BFormDatepicker,
  BBadge,
  BSpinner,
  BCardHeader,
  BCollapse,
		VBToggle} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import ShortTaskForm from "../task/ShortTaskForm";
import { taskApi } from "@/api";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
    name: "BacklogTab",
		components: {
				BTabs,
				BTab,
        BListGroup,
        BListGroupItem,
        AppCollapse,
        AppCollapseItem,
        BFormGroup,
        BFormInput,
        BFormDatepicker,
        BBadge,
        BSpinner,
        ShortTaskForm,
        BCardHeader,
        BCollapse
    },
		directives: {
				'b-toggle': VBToggle,
		},
    props: {
		    tabIndex: {
		    		type: Number,
        },
    },
		data: () => ({
        date1: '',
        date2: '',
        //epics: null,
        isPending: false,
        dataTabIndex: null,
        openCreateTaskForm: [],
        createText: "Создать задачу в эпике",
        closeText: "Закрыть форму"
		}),
  watch: {
      epics: function (val) {
		      if(this.activeEpic) {
						      this.updateActiveEpic(this.epics, this.activeEpic);
				      }
      },
  },
  methods: {
		  ...mapActions("project", ["getBacklogTasks", "getEpics"]),
		  showAllTask() {
          this.$store.dispatch('project/updateActiveEpic', null);
      },
		  createEpic() {
		  		this.$store.commit('studio/UPDATE_MODAL_TASK_TYPE', this.statusEpicId);
          this.$bvModal.show('modal-1');
      },
		  toggleCreateTaskForm(number) {
		  		const issue = this.openCreateTaskForm.includes(number);
		  		if(issue) {
              this.openCreateTaskForm.splice(this.openCreateTaskForm.indexOf(number), 1)
          } else {
              this.openCreateTaskForm.push(number);
          }
      },
		  openTask(epic) {
          taskApi.task(this.currentProject, epic.number).then((res) => {
		          this.$store.dispatch('project/updateActiveTask', res.data.task);
		          this.$root.$emit('bv::toggle::collapse', 'sidebar-right');
          });
      },
		  showEpicTask(epic) {
          this.$root.$emit('bv::toggle::collapse', 'epic'+epic.number);
          this.$store.dispatch('project/updateActiveEpic', epic);
      },
      update() {
		  		if(this.activeEpic) {
              this.loadEpics().then(() => {
		              this.updateActiveEpic(this.epics, this.activeEpic);
              });
             
          }
		  		this.getBacklogTasks();
      },
      updateActiveEpic(epics, activeEpic) {
		  		const current = epics.filter(item => item.number === activeEpic.number );
		  		if(current.length) this.$store.dispatch('project/updateActiveEpic', current[0]);
      },
      loadEpics() {
		      this.isPending = true;
		      return this.getEpics().catch((error) => {
						      this.$bvToast.toast(`Ошибка при загрузке задачи.`, {
								      title: this.$t("Data Update"),
								      variant: "danger",
								      solid: true,
						      });
				      }).then(() => {
						      this.isPending = false;
				      })
      },
  },
  computed: {
      ...mapState("project", ["currentProject", "activeEpic", "epics"]),
		  ...mapGetters("project", ["statusEpicId"]),
  },
  mounted() {
		  setTimeout(() => {this.dataTabIndex = this.tabIndex}, 100)
		  this.loadEpics();
  }
}
</script>

<style scoped>

</style>
