<template>
  <b-modal
    id="modal-start-sprint"
    no-close-on-backdrop
    content-class="shadow"
    title="Начать спринт"
    @show="showModal"
    @hide="hideModal"
  >
    <validation-observer
      ref="startSprintForm"
      #default="{invalid}"
    >
      <b-form>
        <b-form-group label="Дата начала">
          <validation-provider
            #default="{ errors }"
            name="sprintDateStart"
            rules="required"
          >
            <flat-pickr
              v-model="startedAt"
              class="form-control"
              :config="{ enableTime: true, dateFormat: 'Y-m-d H:i', time_24hr: true}"
            />
            <small class="text-danger" v-if="errors[0]">Поле обязательно для заполнения</small>
          </validation-provider>
        
        </b-form-group>
        
        <b-form-group label="Планируемая дата окончания">
          <validation-provider
            #default="{ errors }"
            name="sprintDateEnd"
            rules="required"
          >
            <flat-pickr
              v-model="expiredAt"
              class="form-control"
              :config="{ enableTime: true, dateFormat: 'Y-m-d H:i', time_24hr: true, minDate: nowDate}"
            />
            <small class="text-danger" v-if="errors[0]">Поле обязательно для заполнения</small>
          </validation-provider>
        </b-form-group>
      </b-form>
    </validation-observer>
    <template #modal-footer>
      <button type="button" class="btn btn-secondary" @click="$bvModal.hide('modal-create-sprint');">Отмена</button>
      <button
        type="button"
        class="btn btn-primary"
        @click="formSubmit"
        :disabled="submitDisable"
      >OK</button>
    </template>
  
  </b-modal>
</template>

<script>
		import {
				BModal, BForm, BInputGroup, BFormSelect, BFormGroup, BFormInput, BFormTextarea
		} from 'bootstrap-vue'
		import flatPickr from 'vue-flatpickr-component'
		import { projectApi } from "@/api";
		import { ValidationProvider, ValidationObserver } from 'vee-validate'
		import { required } from '@validations'
		import { mapState, mapActions } from 'vuex';
		import { getErrors } from "@/helpers/errors";
		import moment from "moment";
		
		export default {
				name: "BacklogStartSprint",
				components: {
						BModal,
						BForm,
						BInputGroup,
						BFormSelect,
						BFormGroup,
						BFormInput,
						BFormTextarea,
						flatPickr,
						ValidationObserver,
						ValidationProvider,
				},
				data: () => ({
						startedAt: null,
						expiredAt : null,
						submitDisable: false,
            nowDate: moment().format("YYYY-MM-DD"),
						required,
				}),
				computed: {
						...mapState("project", ["currentProject", "openSprint", "activeSprintStatus"]),
				},
				methods: {
            ...mapActions("project", ["getSprints"]),
						formSubmit() {
								this.$refs.startSprintForm.validate().then(success => {
										this.submitDisable = true;
                    projectApi.editSprint(this.openSprint.uuid, {
                        started_at: this.startedAt,
                        expired_at: this.expiredAt
                    }).then(() => {
		                    this.successToast();
		                    this.$bvModal.hide('modal-start-sprint');
		                    this.getSprints();
                    }).catch(() => {
		                    if(error.response) {
				                    const errors = getErrors(error.response.data.errors);
				                    if(errors.general) {
						                    this.errorToast(errors);
				                    }
		                    }
                    }).then(() => {
		                    this.submitDisable = false;
                    })
								});
						},
						clearData() {
								this.startedAt = null;
								this.expiredAt = null;
						},
						showModal() {
								if(this.openSprint) {
										this.startedAt = this.openSprint.planned_at;
										this.expiredAt = this.openSprint.expired_at;
								}
						},
						hideModal() {
								this.$store.dispatch("project/updateOpenSprint", null);
						},
						successToast() {
								this.$bvToast.toast("Спринт успешно начат", {
										title: this.$t("Start sprint"),
										variant: "success",
										solid: true,
								});
						},
						errorToast(errors) {
								this.$bvToast.toast(`Ошибка при запуске спринта. ERROR: ${errors.general[0].status} ${errors.general[0].message}`, {
										title: this.$t("Start sprint"),
										variant: "danger",
										solid: true,
								});
						}
				},
		}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>


