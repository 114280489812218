import { render, staticRenderFns } from "./BacklogTab.vue?vue&type=template&id=79bfe74f&scoped=true&"
import script from "./BacklogTab.vue?vue&type=script&lang=js&"
export * from "./BacklogTab.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79bfe74f",
  null
  
)

export default component.exports