<template>
  <div class="d-flex">
    <!-- Searchn Input -->
    <b-input-group
      class="input-group-merge ys-search-input mr-2"
      size="sm"
    >
      <b-form-input placeholder="Поиск... " v-model="search" />
      <b-input-group-append is-text>
        <feather-icon icon="SearchIcon" />
      </b-input-group-append>
    </b-input-group>
    
    <!-- Avatar Group -->
    <b-avatar-group size="32px" v-if="config">
      <b-avatar
        v-for="member in config.members"
        v-b-tooltip.hover
        :class="{'pull-up': true, 'active': activeAssigneeList.includes(member.id)}"
        :title="member.firstname + member.lastname"
        :src="member.avatar"
        :key="member.id"
        @click.native="searchAssignee(member)"
      />
      <b-avatar
        v-b-tooltip.hover
        :class="{'pull-up': true, 'active': activeAssigneeList.includes(null)}"
        title="Не назначен"
        @click.native="searchAssignee({id:null})"
        variant="secondary"
      >
        <feather-icon icon="UserIcon" />
      </b-avatar>
    </b-avatar-group>
    
    <b-button
      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
      :variant="activeMyTask ? 'outline-primary' : 'outline-secondary'"
      pill
      size="sm"
      class="ml-2"
      @click="filterMyTask"
    >
      Только мои задачи
    </b-button>
    
    <!--<b-button
      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
      variant="outline-secondary"
      pill
      size="sm"
      class="ml-1"
    >
      Последние обновления
    </b-button>-->
  </div>
</template>

<script>
import { BRow, BCol, BFormInput, BInputGroup, BInputGroupAppend, BAvatarGroup, BAvatar, VBTooltip, BButton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapState } from "vuex";

export default {
  name: "BacklogNav",
  components: {
    BRow,
    BCol,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BAvatarGroup,
    BAvatar,
    BButton,
  },
  props: {
  	config: [Object]
  },
  directives: {
      'b-tooltip': VBTooltip,
      Ripple,
  },
  data: function() {
    return {
        search: "",
        activeAssignee: null,
        activeMyTask: false,
		    activeAssigneeList: [],
    }
  },
		computed: {
				...mapState("user", ["userData"])
		},
  methods: {
		  searchAssignee(member) {
          this.activeMyTask = false;
          if(this.activeAssigneeList.includes(member.id)) {
		          this.activeAssigneeList.splice(this.activeAssigneeList.indexOf(member.id), 1);
          } else {
		          this.activeAssigneeList.push(member.id);
          }
          this.$emit('filterAssignee', this.activeAssigneeList);
      },
		  filterMyTask() {
          this.activeAssigneeList = [];
		  		if(this.activeMyTask) {
              this.activeMyTask = false;
              this.$emit('filterAssignee', []);
          } else {
              this.activeMyTask = true;
              const member = JSON.parse(this.userData);
              this.$emit('filterAssignee', [member.id]);
          }
      }
  },
  watch: {
      search: function (val) {
         this.$emit('search', val);
      },
  }
}
</script>

<style scoped>
.b-avatar.active {
  box-shadow: 0 0 0 2px #7367f0, inset 0 0 0 1px rgb(34 41 47 / 7%);
}
</style>
